.verification-wrapper {
  padding: 10px;
  margin-top: 60px;
}
.verification {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.verification__wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 0;
  margin: 0;
  width: var(--w-width);
  justify-content: space-between;
  flex-direction: column;
  height: var(--w-height);
}
.verification__fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 0;
  margin: 0;
  width: var(--w-width);
  height: 25%;
  margin-right: 9px;
  margin-left: 9px;
}
.verification__field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 0;
  margin: 0;
  width: var(--w-width);
  justify-content: space-between;
  letter-spacing: 1.7px;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  stroke: none;
  box-shadow: none;
  width: 100%;
  height: 100%;
  height: 60px;
  &:hover {
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }
}
.verification__verify {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 0;
  margin: 0;
  width: var(--w-width);
  height: 25%;
}
.verification__header {
  padding: 0;
  margin: 0;
  width: var(--w-width);
  margin-right: 9px;
  margin-left: 9px;
}
.text-wrapper {
  padding: 0;
  margin: 0;
  width: var(--w-width);
}
.verification_head {
  .main-text {
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 141.19%;
    color: #151940;
  }
  .desc {
    width: 80%;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    color: #151940;
    margin-bottom: 50px;
  }
}
.verification_footer {
  .duration {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 25px;
    letter-spacing: -0.325px;
    color: #5771f9;
    margin-top: 20px;
  }
  .info {
    width: 85%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.325px;
    color: #486484;
    margin-top: 40px;
  }
}
.verification__input {
  letter-spacing: 1.7px;
  padding: 0;
  margin: 5px;
  border: none;
  outline: none;
  stroke: none;
  box-shadow: none;
  transition: 0.2s 0s ease-out;
  width: 60px;
  height: 60px;
  border-radius: 9px;
  padding: 16px 0;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  // border: 1px solid #818181;
  background-color: #573A7A;
  color: #fff;
  // box-shadow: rgba(4, 4, 179, 0.1) 0px 8px 24px,
  //   rgba(0, 0, 147, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  &:hover {
    box-shadow: 0 0 0 0.25rem var(--color-input-hover);
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem var(--color-input-focus);
  }
}
.verification__input_aadhar {
  width: 40px !important;
  height: 40px !important;
  font-size: 1.5rem !important;
}
.verification__timeout {
  margin-right: 9px;
  margin-left: 9px;
}

.form-box {
  padding: 30px;
}

.appbar-wrapper-white {
  background-image: none;
  background-color: #4d61fc;
}

.appbar {
  width: 50%;
  bottom: auto !important;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
