@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

.bg-new {
  background-color: #F9F8FF;
}

// DemoHome style
.create-card-new {
  display: inline-block;

  // @include fade-transition(background);


  .avail-button {
    position: absolute;
    bottom: 20px;
    padding: 8px 25px !important;
    // z-index: 1000;
  }

  .instant-button {
    // position: absolute;
    // bottom: 20px;
    // z-index: 1000;
  }
}

.card-new-home {
  position: relative;
  width: 100%;
  height: 200px;
  background: #FFFFFF;
  border-radius: 18px;
  // box-shadow: -5px 8px #333333;
  padding: 20px;

  .desc-text {
    font-size: 15px;
    font-weight: 400;
    color: white;
  }

  .button-holder {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .btn-new {
    background: #4A3163 !important;
    color: white !important;
    box-shadow: none !important;
    text-transform: none !important;
    border-radius: 8px !important;
  }
}

.card-2-new-home {
  position: relative;
  width: 100%;
  height: 180px !important;
  background: #FFFFFF;
  border-radius: 18px;
  overflow: hidden;

  .heading {
    font-size: 15px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 10px;
  }

  .desc-text {
    font-size: 15px;
    font-weight: 400;
    color: white;
  }

  .button-holder {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .btn-new {
    background: #4A3163 !important;
    color: white !important;
    box-shadow: none !important;
    text-transform: none !important;
    border-radius: 8px !important;
  }
}

.card-2-main {
  padding: 10px 20px 0px 10px;
}

.button-holder {
  position: absolute;
  bottom: 20px;
}

.card-2-header {
  display: flex;
  background-color: #EDDCFF;
  justify-content: space-between;
  padding: 10px;
}

.heading-new {
  font-size: 1.15em;
  font-weight: 600;
  color: #4A3163;
  margin-bottom: 10px;
}

.sub-heading-new {
  font-size: 10px;
  font-weight: 400;
  color: #4A3163;
  margin-bottom: 10px;
  margin-top: 5px;
}

.sub-heading-2-new {
  font-size: 14px;
  font-weight: 400;
  color: #4A3163;
  margin-bottom: 10px;
  margin-top: 5px;
}

.desc-text {
  font-size: 15px;
  font-weight: 400;
  color: white;
}

.p-no-margin {
  margin: 0;
}

.coin-card {
  width: 88.57px;
  height: 94px;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.coin-card-badge {
  width: 20px;
  height: 30px;
  position: absolute;
  background-color: #4A3163;
  color: #fff;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: #fff;
}


.triangle-up {
  width: 0;
  height: 0;
  position: absolute;
  bottom: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

.coin-badge-text {
  font-size: 7px;
  margin: 0;
}

.icon-div {
  color: #6A6A6A;
  margin: 15px 0 0 25px;
  width: 50px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coin-card-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.coin-card-main-text {
  font-weight: 800;
  font-size: 8px;
  color: #4A3163;
  margin: 0;
}

.coin-card-main-button {
  width: 55.74px;
  height: 17.49px;
  background: #F8F2FF;
  border: 0.704414px solid #4A3163;
  border-radius: 4.552px;
  font-size: 10px;
  color: #4A3163;
}
// DemoHome style end


// DealerProfile
.page-head-profile {
  font-size: 22px;
  color: #573a7a;
  font-weight: 500;
  height: 6em;
  background-color: #F9F3FF;
}


.dp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  flex-direction: column;
}

.dp-box {
  position: relative;
  display: flex;
  justify-content: center;
  display: inline-block;
  width: 5.5em;
  height: 5em;
}

.dp {
  border-radius: 50%;
  border: 1px solid #4A3163;
  width: 5em;
  height: 5em;
}

.dp-change {
  position: absolute;
  bottom: 1.5em;
  right: 0;
  border-radius: 50%;
  border: 1px solid #4A3163;
  width: 1em;
  height: 1em;
}

.dp-name,
.dp-phone {
  text-align: center;
  margin: 0;
  color: #4A3163;
}

.dp-phone {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
}

.profile-info-card-container {
  background-color: #F9F3FF;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.profile-info-card {
  background-color: #fff;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
}

.pc-title {
  margin: 0;
  padding: 0.5em;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: #573A7A;
  background: #F9F3FF;
  border-radius: 10px;
  display: inline-block;
  flex-wrap: wrap;
}

.pc-subtitle {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.5px;
  color: #573A7A;
}

.pc-btn {
  background: #4A3163;
  border: 1px solid #4A3163;
  border-radius: 4.47px;
  color: #fff;
}

.logout-btn-new {
  border: 1px solid #4A3163;
  border-radius: 4px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #4A3163;
  width: 112px;
  height: 32px;
}
// DealerProfile End

// menu
.content-center-new {
  display: flex;
  justify-content: center;
  // margin-bottom: 20px;
  // height: 100svh;
  // background-color: #F9F8FF;
}


.home-new-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  border-left: 0.7em solid #BE9FE1;
  border-radius: 10px;
  background-color: #fff;
  padding: 0.7em 0.5em;
}

.home-btn-label {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 17.707px;
  line-height: 21px;
  color: #333333;
  padding-bottom: 0.2em;
}

.home-btn-sub-label {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #333333;
}

.home-btn-icon-container {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-btn-label-container {
  text-align: left;
  padding-left: 1em;
}
// menu end

// post disbursal document
.collapse-blue-new {
  border: none;
  margin-bottom: 15px !important;
  margin-top: 5px !important;
  border-radius: 5px !important;
  color: #573A7A !important;
  box-shadow: none !important;
  background-color: #fff;
}

.card-container {
  display: flex;
  justify-content: space-between;
}

.card-left {
  display: grid;
  align-items: center;
}

.doc-status {
  font-size: 0.8em;
}

// .app-p {
//   width: 100%;
//   // word-break: break-all;
//   margin-bottom: 0 !important;
//   color: #573A7A;
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 600;
//   font-size: 14px;
// }

.app-date {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #573A7A;
}

.search-spacing {
  padding: 0 1em;
}

.fu-container {
  // background-color: red;
  // width: 50px;
  // height: 50px;
  width: 80%;
}
// post disbursal document end

// borrower-profile
.client-card {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 1em;
  margin-bottom: 1em;
}

.cc-head {
  background: #EDDCFF;
  padding: 0.2em 0.2em 0.2em 0;
}

.cc-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: #573A7A;
  padding-left: 1em;
}

.cc-metainfo-container{
  padding-left: 1em;
  padding-bottom: 1em;
}

.cc-metainfo {
  margin: 0;
  padding-bottom: 0.2em;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: #573A7A;
  width: 50%;
}
// borrower-profile end

// collection
.page-head-collection {
  font-size: 22px;
  color: #573a7a;
  font-weight: 500;
  // height: 4em;
  background-color: #F9F3FF;
  border-radius: 0px 0px 20px 20px;
}
// collection-end


// amount credited
.page-head-secondary {
  font-size: 22px;
  color: #573a7a;
  font-weight: 500;
  padding-bottom: 1em;
  background-color: #F9F3FF;
  border-radius: 0px 0px 20px 20px;
}

.amt-card {
  background-color: #EDDCFF;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
  height: 4em;
  padding: 0.5em 0;
}

.ac-amt {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.5px;
  padding-bottom: 0.2em;
  margin-bottom: 0;
  color: #573A7A;
}

.ac-label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.5px;
  padding-bottom: 0;
  margin-bottom: 0;
  color: #573A7A;

}
// amount credited end