.inventory_app_text {
  font-size: 20px;
  margin: 0;
  color: purple;
}

.inventory_card {
  padding: 20px;
  position: relative;
}
.inventory_brand_name {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  color: purple;
}

.inventory_features {
  margin-bottom: 2px;
  .inventory_features_head {
    font-size: 12px;
    margin-bottom: 0;
    color: gray;
  }

  .inventory_value {
    font-size: 15;
    font-weight: 500;
    margin-left: 10px;
  }
}

.inventory_order_button {
  display: none;
  position: absolute !important;
  top: 15px !important;
  right: 15px !important;
  color: purple !important;
  border-color: purple !important;
}

.inventory_quantiy_card {
  width: 300px;
  padding: 10px;
}

.inventory_quanity_head {
  width: 100%;
  text-align: center;
  color: gray;
  margin-top: 20px;
}

.inventory_quanity_text {
  width: 100%;
  text-align: center;
  color: purple;
  font-size: 40px;
  margin-top: 20px;
}

.inventory_quanity_button{
    margin-bottom: 20px !important;
    background-color: purple !important;
    margin-top: 20px !important;
}

.inventory_button_cancel{
    background-color: white !important;
    color: purple !important;
}