.reward-button{
  background-color: #BE9FE1;
  padding: 0 0 0 10px;
  border-radius: 15px;
  margin:15px 0;
  cursor: pointer;
  margin-right: 0.5rem !important ;
  display: flex;
  align-items: center;
}

.reward-sec-title{
  font-size: 26px;
  font-weight: 400;
  margin-bottom: .5rem;
}

.reward-card-home {
  position: relative;
  width: 100%;
  // height: 200px;
  background: linear-gradient(110.7deg, #7246A8 3.18%, #430094 45.06%, #CBBEF2 140.41%);
  border-radius: 8px;
  overflow: hidden;

  .reward-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  .heading {
    font-size: 26px;
    font-weight: 400;
    color: #fff;
    padding-bottom: 15px;
  }

  .subheadingcon{
    display: flex;
    flex-direction: column;
  }
  .subheading{
    font-size: 15px;
    font-weight: 500;
    color: #fff;
  }

  .heading2{
    font-size: 25px;
    font-weight: 400;
    color: #fff;
  }

}

.reward-create-card {
  /*  styling */
  display: inline-block;
  padding: 25px 0px 25px 15px;
  // border: 3px solid #fafafa;


}

.reward-task-card{
  padding:5px;
  border-radius: 10px;
  box-shadow: 6px 6px 30px 0px rgba(125, 153, 180, 0.2);
  background: rgba(239, 239, 239, 1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  // height: 35px;

  
  justify-content: space-between;

  .player{
    height: 45px;
  }
  .right{
    font-size: 20px;
    font-weight: 400;
  }
  .left{
    display: flex;
    align-items: center;
  }

  .earn{
    font-size: 14px;
    font-weight: 400;
  }
} 

.reward-coming-soon{
  display: flex;
  flex-direction: column;
  gap:15px;
  justify-content: center;
  align-items: center;
}



.reward-tabs-wrapper {
  border: 1px solid #573A7A;
  // background-color: rgba(243, 245, 250, 1) !important;
  height: 100px;

  .app-bar {
    display: flex;
    align-items: center;
  }

  .tab {
    color: rgba(39, 67, 132, 1) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    padding-top: 0 !important;
    background: white;
  }

  .active {
    background-color: #6c63ff !important ;
    color: white !important;
  }

  .tab-name {
    margin-bottom: 0;
    color: #573A7A;
  }
  .coin-value {
    color: #f7cb15;
  }
  .tab-subtext {
    font-weight: 500;
    margin-left: 10px;
  }
  .white {
    color: white !important;
  }
  .purple {
    color: #7b61ff !important;
  }
}

.step {
  position: relative;
  min-height: 6em;
  color: gray;
  font-size: 13px;
}
.step > div:first-child {
  position: static;
  height: 0;
}
.step > div:not(:first-child) {
  margin-left: 1.5em;
  padding-left: 1em;
}
.step.step-active {
  color: #6f7482;
}
.step.step-active .circle {
  background-color: #2ab514;
  border: 1px solid #2ab514 !important;
}

/* Circle */
.circle {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  border-radius: 100%;
  border: 1px solid gray;
  color: #fff;
  text-align: center;
  box-shadow: 0 0 0 3px #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Vertical Line */
.circle:after {
  content: " ";
  position: absolute;
  display: block;
  top: 10px;
  right: 50%;
  bottom: 1px;
  left: 50%;
  height: 100% !important;
  width: 1px;
  transform: scale(1, 2);
  transform-origin: 50% -100%;
  // background-color: rgba(0, 0, 0, 0.25);
  z-index: -1;
  border-left: 2px dotted rgb(165, 165, 165);
}

.step:last-child .circle:after {
  display: none;
}

/* Stepper Titles */
.title {
  line-height: 1.5em;
}
.caption {
  font-size: 0.8em;
}

.done-ico {
  font-size: 12px !important;
  color: white !important;
  font-weight: 500;
}

.info-div {
}

.reward-date {
  text-align: center;
  font-size: 10px;
  color: gray;
  margin-bottom: 0;
}

.coin-text {
  text-align: center;
  color: #00a945;
  margin-bottom: 0;
}

.reward-wallet {
  display: flex;
  position: fixed;
  top: 20px;
  right: 0;
  z-index: 6000;
  background-color: #6c63ff !important;
  color: white;
  padding: 5px 0;
  border-radius: 40px 0 0 40px;
  right: -50px;
  transition: 0.5s ease;
  cursor: pointer;
}

.coin {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: #f7cb15;
}
.bottom-text {
  margin-bottom: 0;
  font-size: 12px;
}

// .reward-wallet:hover {
//   right: 0;
// }
.reward-cup{
  padding: 0 5px;
}

.reward-expanded{
  right: 0 !important;
}

.reward-animation-wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.444) !important;
  top: 0;
  z-index: 10000;
}

.reward-card {
  overflow: hidden;
  width: 300px;
  background: white;
  border-radius: 10px;
}

.reward-btn {
  width: 150px !important;
  color: #6c63ff !important;
  margin-bottom: 20px !important;
  border-radius: 30px !important;
}

.info-hand{
  animation: jumpInfinite 1.5s infinite;
}




@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}