.documents-upload-wrapper .react-tabs {
  display: flex;
  color: rgb(10, 10, 10) !important;
  background: #f9f8ff !important;
  height: 100vh;
  position: fixed;
  width: 50%;
}

.documents-upload-wrapper .react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 90px;
  height: 100%;
  margin: 0;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 100px;
  background: rgb(255, 255, 255);
  overflow-y: scroll;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 1000;
  line-height: 12px !important;
  color: #573a7a;
}

.documents-upload-wrapper .react-tabs__tab-list::-webkit-scrollbar {
  width: 0;
}

.documents-upload-wrapper .react-tabs__tab {
  height: 70px;
  list-style: none;
  cursor: pointer;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
}

.documents-upload-wrapper .react-tabs__tab--selected {
  background: #573a7a;
  color: rgb(255, 255, 255);
  border: none !important;
  border-radius: 0 16px 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.documents-upload-wrapper .react-tabs__tab-panel {
  display: none;
  padding: 20px;
  height: 450px;
  overflow-y: scroll;
  height: 100% !important;
  padding-bottom: 150px;
  width: 100%;
}

.documents-upload-wrapper .react-tabs__tab-panel--selected {
  display: block;
}

.documents-upload-wrapper .react-tabs__tab--selected:focus {
  outline: none;
}

.documents-upload-wrapper .panel-content {
  text-align: center;
  padding-left: 30px;
}

.documents-upload-wrapper .tab-title-holder {
  display: flex;
  width: 85%;
  justify-content: space-between;
}

.documents-upload-wrapper .tab-title {
  font-size: 10px;
  margin-bottom: 0;
  margin-top: 8px;
  text-align: center;
}

.documents-upload-wrapper .react-tabs__tab-list svg path {
  fill: #573a7a;
}

.documents-upload-wrapper .react-tabs__tab--selected svg path {
  fill: #ffffff !important;
}

.documents-upload-wrapper .banner {
  position: absolute;
  background: #573a7a;
  color: #fff;
  right: -10px;
  top: -8px;
  font-size: 10px;
  padding: 6px 6px;
  border-radius: 30px 30px 30px 0;
  border: 2px solid #fff;
}

.documents-upload-wrapper .banner span {
  font-size: 7px !important;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbbaba;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #adadad;
}

.documents-card-v3 {
  position: relative;
  background: #eae4ff;
  height: 120px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.documents-card-v3 .heading {
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  padding: 0 10px;
}

.doc-modal {
  width: 500px;
}

.documents-card-v3 .capture-btn {
  border: 2px solid #573a7a !important;
  color: #573a7a !important;
  text-transform: none !important;
  font-size: 12px !important;
  margin-right: 20px !important;
  padding: 5px 20px !important;
}
.documents-card-v3 .upload-btn {
  border: 2px solid #573a7a !important;
  background: #573a7a !important;
  color: white !important;
  text-transform: none !important;
  font-size: 12px !important;
  padding: 5px 20px !important;
}

.extra-height {
  height: 160px !important;
}

.pop-dialog-card {
  .pop-head {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    padding: 0 10px;
    color: #573a7a;
  }

  .pop-desc {
    font-size: 16px;
    color: #573a7a;
  }

  button {
    align-self: center !important;
    width: 150px !important;
    border-radius: 30px !important;
  }
}

.rejection-popup-wrapper {
  position: absolute;
  z-index: 2000;
}

.rejection-popup {
  position: relative;
  background-color: #d81d1d;
  color: white;
  width: 95%;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  margin-top: 10px;
}

.rejection-popup .arrow-icon{
  position: absolute;
  top: -13px;
}

.reject-info-button{
  color: #d81d1d !important;
}
.reject-info-button .mail-icon{
  color: #d81d1d !important;
}

.courier-menu-btn {
  width: 100% !important;
  border: 2px solid #573a7a !important;
  color: #573a7a !important;
  text-transform: none !important;
  font-size: 12px !important;
  padding: 5px 20px !important;
}

.courier-menu-btn {
  width: 100% !important;
  border: 2px solid #573a7a !important;
  color: #573a7a !important;
  text-transform: none !important;
  font-size: 12px !important;
  padding: 5px 20px !important;
}

.courier-menu-btn-fill {
  width: 100% !important;
  border: 2px solid #573a7a !important;
  background: #573a7a !important;
  color: white !important;
  text-transform: none !important;
  font-size: 12px !important;
  padding: 5px 20px !important;
}