.toggle-btn {
    font-size: 13px !important;
    text-transform: none !important;
    padding: 5px 15px !important;
}

.loan-table-container {
    margin: auto 0;
    height: 76vh;
    overflow: scroll;
    scroll-behavior: smooth;
}

.loan-table {
    width: 100%;
    border-collapse: collapse;
    /* border: 1px solid #000; */
    /* border-spacing: 0em; */
}

.loan-tr {
    background-color: #ffffff !important;
    /* cursor: pointer; */
    border-bottom: 1pt solid rgb(204, 204, 204);
}


.loan-table thead th {
    position: sticky;
    z-index: 1000;
    top: 0;
}

.loan-table thead tr th {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.35px;
    /* color: #fff; */
    background: #dedbed;
    opacity: 1;
    padding: 0.8em 0.3em 0.8em 0.3em;
    vertical-align: top;
    text-align: center;
}

.loan-table tbody tr td {
    width: 500px;
    font-size: 0.8em;
    letter-spacing: 0.35px;
    padding: 5px 10px;
    font-weight: normal;
    color: #000;
    text-align: left;
    border: none;
}