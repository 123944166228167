@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500&display=swap");

.form-input {
  height: 50px !important;
  background-color: #f3f8fd !important;
  border: none !important;
  padding: 20px !important ;
  border-radius: 3px !important;

  &:focus {
    outline: none;
  }
}

.form-input:disabled {
  background-color: #ededed !important;
}

.form-input-area {
  background-color: #f3f8fd !important;
  border: none !important;
  padding: 20px !important ;
  border-radius: 3px !important;

  &:focus {
    outline: none;
  }
}

.form-input-area:disabled {
  background-color: #ededed !important;
}

.form-input-bank {
  padding-right: 40px !important;
}

.input-blue {
  height: 30px !important;
  // border: 1px solid #2f60d1 !important;
  background-color: #f3f8fd !important;
  padding: 15px !important;
}

.input-blue:disabled {
  background-color: #ededed !important;
}

.white-bg {
  background-color: #ffffff !important;
}

.input-blue-search {
  padding-top: 10px !important;
  height: 35px !important;
}

.form-input::placeholder {
  color: #b8bcca;
  opacity: 1;
  font-size: 11px;
  font-weight: 300;
}

.form-input-area::placeholder {
  color: #b8bcca;
  opacity: 1;
  font-size: 11px;
  font-weight: 300;
}

.input-pan {
  position: relative;
}

.pan-success {
  position: absolute;
  right: 20px;
  left: auto;
  top: 30px;
}

.form-label {
  color: #6f7482 !important;
  font-size: 12.5px;
}
.form-label-black {
  color: #161616 !important;
  font-size: 12.5px;
}

.form-head {
  width: 100%;
  font-size: 15px;
  color: #c1c1c1 !important;
  padding-bottom: 8px;
  border-bottom: 1px solid #cfcfcf;
  margin-bottom: 15px;
}
.form-desc {
  font-size: 11px;
  color: #a6a6a7 !important;
}

.main-wrapper {
  padding: 20px;
}

.button-wrapper {
  padding: 0 20px;
}
.btn-submit {
  width: 100% !important;
  background-color: #573a7a !important;
  height: 50px !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  color: #fff !important;
}

.btn-submit-disabled {
  background-color: #bca0de !important;
  height: 50px !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-submit-white {
  color: #573a7a !important;
  background-color: #fff !important;
  font-weight: 700 !important;
}

.btn-skip {
  width: 100% !important;
  background-color: #ffffff !important;
  height: 50px !important;
  box-shadow: none !important;
  border: 2px solid #573a7a !important;
  border-radius: 10px !important;
  color: #573a7a !important;
}

.verify-txt {
  font-size: 13px;
  color: #2f60d1;
}

.mand-star {
  color: red !important;
}

.error {
  width: 100%;
  color: red !important;
  font-size: 10px;
  margin-bottom: 0;
  display: block;
  word-wrap: break-word;
}

.capture-error {
  box-shadow: rgba(234, 14, 14, 0.258) 0px 8px 24px !important;
  border: 1px solid red;
}

.loading-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aadhaar-wrapper {
  padding: 20px;
}

.content-center {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.Denied,
.Unsupported {
  background-color: rgb(213, 16, 16) !important;
  color: white !important;
  padding: 5px 15px;
  border-radius: 8px;
}

.Pending {
  background-color: rgb(213, 154, 16) !important;
  color: white !important;
  padding: 5px 15px;
  border-radius: 8px;
}

.Allowed {
  background-color: rgb(33, 170, 53) !important;
  color: white !important;
  padding: 5px 15px;
  border-radius: 8px;
}

.appbar-name {
  margin-bottom: 0px;
  color: gray;
  font-size: 16px;
}

.snackbar-button {
  color: white !important;
}

.paper-bottom {
  // background-color: black !important;
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  box-shadow: none !important;
  background-color: #573a7a !important;
  color: white !important;
}

.bottom-nav {
  display: flex !important;
  justify-content: center !important;
  background-color: #573a7a !important;
  color: white !important;
}

.terms-check-text {
  font-size: 12px;
  line-height: 18px !important;
  color: rgba(111, 116, 130, 1);
}

.multi-upload-btn {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  border: 1px solid #eeeff0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.mini-heading {
  font-weight: 500;
  color: #2f60d1;
}

.hidden {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.safe-txt {
  color: #6f7482;
  font-size: 10px;
  text-align: center;
  margin-bottom: 10px;
}

.page-head {
  font-size: 22px;
  color: #573a7a;
  font-weight: 500;
}

.pointer {
  cursor: pointer !important;
}

.search-ico {
  position: absolute;
  right: 10px;
  top: 6px;
}

.chip-blue {
  cursor: pointer;
  height: 40px !important;
  width: 100% !important;
  border-color: #573a7a !important;
  color: #573a7a !important;
  border-radius: 10px !important;
}

.chip-blue-fill {
  cursor: pointer;
  width: 100% !important;
  height: 40px !important;
  background-color: #573a7a !important;
  border-color: #573a7a !important;
  color: rgb(255, 255, 255) !important;
  border-radius: 10px !important;
}

.app-p {
  width: 100%;
  // word-break: break-all;
  margin-bottom: 0 !important ;
}

// .app-p {
//   word-break: break-all;
//   margin-bottom: 0 !important ;
// }

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.doc-uploaded-success {
  cursor: pointer;
  font-size: 20px;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  .success-tick {
    color: green;
    margin-right: 10px;
    font-size: 30px;
  }
}

.loan-summary {
  position: relative;
  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #d3c9f2;
  padding: 20px;
  border-radius: 8px;
}

.loan-summary .head {
  margin-top: 20px !important;
  margin-bottom: 30px;
  font-size: 14px;
}

.loan-summary .key {
  font-size: 13px;
  color: #430546;
}

.loan-summary .value {
  font-size: 13px;
  font-weight: 700;
}

.loan-summary .agree-img {
  width: 150px;
  right: 0;
  top: -30px;
  position: absolute;
}

.align-left {
  display: flex;
  justify-content: start !important;
}

.align-right {
  display: flex;
  justify-content: end !important;
}

.overlay-screen {
  position: absolute;
  width: 100% !important;
  height: 100%;
  background-color: rgb(255, 255, 255) !important;
  top: 0;
  z-index: 6000 !important;
  display: flex;
  justify-content: center;
}

.multi-card-holder {
  padding-bottom: 100px !important;
}

.overflow-doc-list {
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

// CheckBox

.custom-checkbox {
  width: 300px;
  height: 50px;
  input#status {
    display: none;

    // Unchecked State
    + label {
      height: 100%;
      width: 100%;
      > .status-switch {
        cursor: pointer;
        width: 100%;
        height: 100%;
        position: relative;
        background-color: #ededed;
        color: #c7c7c7;
        transition: all 0.5s ease;
        padding: 3px;
        border-radius: 30px;

        &:before,
        &:after {
          border-radius: 2px;
          height: calc(100% - 6px);
          width: calc(50% - 3px);
          display: flex;
          align-items: center;
          position: absolute;
          justify-content: center;
          transition: all 0.3s ease;
        }

        &:before {
          background-color: white;
          color: #502bac;
          border-radius: 30px;
          left: 3px;
          z-index: 10;
          content: attr(data-unchecked);
        }

        &:after {
          right: 0;
          content: attr(data-checked);
        }
      }
    }

    // Checked stae
    &:checked + label > .status-switch {
      background-color: #ededed;

      &:after {
        left: 0;
        content: attr(data-unchecked);
      }

      &:before {
        color: #502bac;
        left: 50%;
        content: attr(data-checked);
      }
    }
  }
}

.timer-digit {
  font-size: 30px;
  font-family: "Orbitron", sans-serif !important;
  font-weight: 500;

  .time-holder {
    background-color: #2c2b2e !important;
    width: 80px !important;
    height: 70px !important;
    border-radius: 10px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: white !important;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  // background: #000000;
}
.loader {
  display: flex;
  align-items: center;
  &__part {
    background: #ffffff;
    width: 5px;
    border-radius: 3px;
    margin: 0 5px;
    opacity: 0;
  }
}

.error-message-wrapper {
  padding: 0 20px;
}

@for $i from 1 through 3 {
  .loader__part--#{$i} {
    animation: opacity 0.3s linear forwards,
      anim-#{$i} 0.5s cubic-bezier(0.8, 0, 0.6, 1) infinite alternate;
    animation-delay: 0.3s * $i;
  }
  @keyframes anim-#{$i} {
    0% {
      height: 5px;
    }
    100% {
      height: 32px + ($i * 3);
    }
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.bank-search-text input {
  padding: 8px 20px !important;
  padding-left: 0px !important;
}

.bank-no-btn {
  background-color: #f9f8ff !important;
  color: #ee0000 !important;
  text-transform: none !important;
  font-weight: 300 !important;
  padding: 10px !important;
}

.disabled-grid {
  position: relative !important;
}

.disabled-grid::before {
  content: "" !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: white !important; 
  opacity: 0;
  z-index: 5000 !important;
}
