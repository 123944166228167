.tabs-wrapper {
  height: 100px !important;
}

.tab-header {
  width: 100%;
  text-align: center;
}

.upload-doc-card {
  display: flex;
  justify-content: center;
}

.absolute-view {
  position: relative;
}

.delete-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: white;
  border-radius: 20px;
  color: rgb(207, 0, 0);
  cursor: pointer;
}

.combine-icon {
  color: rgb(136, 5, 201);
  margin-right: 10px;
  font-size: 30px;
}

.page-count-badge{
  background-color: #573A7A;
  color: rgb(244, 244, 244);
  display: inline-block;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}