$breakpoint-tablet: 768px;
@media (min-width: $breakpoint-tablet) {
  .login-image {
    width: 300px;
  }

  .perm-instruction-img {
    width: 200px;
  }

  .permission-responsive {
    width: 50% !important;
    padding: 50px 30px !important;
  }

  .response-wrapper {
    width: 50% !important;
  }
  .kyc-wrapper {
    width: 50% !important;
  }

  .cheque-dialog {
    width: auto !important;
  }
  // .app-wrapper{
  //   display: flex;
  //   justify-content: center;
  // }
  .button-wrapper {
    width: 50% !important;
  }

  .tab-fixed {
    width: 50% !important;
  }

  .logout-btn {
    width: 50% !important;
  }

  .battery-holder {
    width: 45%;
  }
}

@media only screen and (max-width: 600px) {
  .info-text {
    width: 50%;
  }

  .documents-upload-wrapper .react-tabs {
    width: 100% !important;
  }

  .upload-txt-v2 {
    font-size: 10px !important;
  }

  .payment-card {
    .upload-card-v3 {
      height: 130px !important;

      .heading {
        font-size: 15px !important;
        font-weight: 600;
        color: #333333;
        margin-bottom: 10px;
      }

      .desc-text {
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
  .doc-modal {
    width: 100%;
  }

  .crop-comp img {
    width: 100%;
    height: auto !important;
    max-height: 550px !important;
    object-fit: contain;
  }

  .change-lang-btn-mob {
    display: block !important;
  }

  .change-lang-btn {
    display: none !important;
  }

  .error-message-wrapper {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 1100px) {
  .documents-card-v3 .upload-btn {
    font-size: 10px !important;
    padding: 0px !important;
  }
  .documents-card-v3 .capture-btn {
    font-size: 10px !important;
    padding: 0px !important;
    margin-right: 10px !important;
  }
}

@media only screen and (max-width: 400px) {
  .chip-blue {
    font-size: 10px !important;
  }
  .chip-blue-fill {
    font-size: 10px !important;
  }
}
