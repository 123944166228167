.battery {
  width: 100%;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 5px;
  position: relative;

  &:after {
    content: " ";
    top: 25%;
    right: -8px;
    height: 12px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    width: 7px;
    position: absolute;
    background: rgb(0, 0, 0);
  }
}

.bar {
  cursor: pointer;
  display: inline-block;
  width: 10%;
  border: 2px solid rgb(255, 255, 255);
  height: 100% !important;
  background: rgba(186, 189, 236, 0.579);
  transition: background 1s;
  border-radius: 4px !important;
}

.battery-active {
  background: #275791;
}
